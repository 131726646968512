import { MouseEventHandler } from "react";
import { ChevronLeft } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NavContainer } from "./nav-container";

type NavBackProps = {
  onClick?: MouseEventHandler;
};

export const NavBack = ({ onClick }: NavBackProps) => {
  const navigate = useNavigate();
  return (
    <NavContainer>
      <Button
        startIcon={<ChevronLeft />}
        onClick={onClick ?? (() => navigate(-1))}
      >
        Back
      </Button>
    </NavContainer>
  );
};
