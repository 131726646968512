import axios from "axios";
import { getApiEndpoint } from "../utils/fallback";

export const login = (code: string, redirect: string) =>
  axios
    .post(
      `${getApiEndpoint()}/admin/users/login`,
      {
        code,
        redirect,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    .then((res) => res.data);

export const getUser = (): Promise<{ email: string }> =>
  axios.get(`${getApiEndpoint()}/users/me`).then((res) => res.data);
