import {
  IncidentCollectionIngestRules,
  CollectionIngestRules,
  isIncidentCollectionIngestRule,
} from "../../../api/rules";
import { IncidentsRulesTable } from "./incidents-rules-table";
import { GenericRulesTable } from "./generic-rules-table";

export const RulesTable = ({ data }: { data: CollectionIngestRules }) => {
  if (isIncidentCollectionIngestRule(data)) {
    return <IncidentsRulesTable data={data as IncidentCollectionIngestRules} />;
  }

  return <GenericRulesTable data={data} />;
};
