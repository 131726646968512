import { useCallback, useMemo, useRef } from "react";
import { BroadcastTemplate, Coordinates } from "../../../api/broadcast";
import {
  BroadcastAreaMap,
  BroadcastAreaPolygon,
} from "../../../components/broadcast-area-map";
import { getCenterOfPolygons, parsePolygon } from "../../../utils/geo";
import { CreateBroadcastControl } from "../types";
import { useSelectedBroadcastTemplates } from "./shared";
import { Controller, useWatch } from "react-hook-form";
import { DrawingManager } from "@react-google-maps/api";
import { BROADCAST_AREA_POLYGON_OPTIONS } from "../../../components/broadcast-area-map/broadcast-area-polygon";

type SelectedBroadcastAreaMapProps = {
  control: CreateBroadcastControl;
  templates?: BroadcastTemplate[];
};

export const CreateBroadcastAreaMap = ({
  control,
  templates,
}: SelectedBroadcastAreaMapProps) => {
  const polygonRef = useRef<google.maps.Polygon>();
  const selectedBroadcastTemplates = useSelectedBroadcastTemplates({
    control,
    templates,
  });

  const watchType = useWatch({ control, name: "type" });
  const watchSelectMode = useWatch({ control, name: "selectMode" });

  const selectedAreaPolygons = useMemo(
    () =>
      selectedBroadcastTemplates.map((template) =>
        parsePolygon(template.polygon)
      ),
    [selectedBroadcastTemplates]
  );

  const centerOfSelectedPolygons = useMemo(
    () => getCenterOfPolygons(selectedAreaPolygons),
    [selectedAreaPolygons]
  );

  const onPolygonComplete = useCallback(
    (poly: google.maps.Polygon, onChange: (coords: Coordinates) => void) => {
      polygonRef.current = poly;

      const coords: Coordinates = [];
      poly
        .getPaths()
        .getAt(0)
        .forEach((a) => {
          coords.push([a.lng(), a.lat()]);
        });

      // close the loop
      coords.push(coords[0]);

      onChange(coords);
    },
    []
  );

  return (
    <BroadcastAreaMap center={centerOfSelectedPolygons}>
      {watchSelectMode === "list" &&
        selectedAreaPolygons.map((polygon, idx) => (
          <BroadcastAreaPolygon key={idx} paths={polygon?.paths} />
        ))}
      <Controller
        control={control}
        name="drawnPolygon"
        render={({ field: { value, onChange } }) => {
          if (polygonRef.current && !value.length) {
            polygonRef.current.setMap(null);
          }

          return (
            <DrawingManager
              options={{
                drawingControl: false,
                drawingMode:
                  watchType === "ALL_DEVICES" ||
                  watchSelectMode === "list" ||
                  value.length
                    ? null
                    : google.maps.drawing.OverlayType.POLYGON,
                polygonOptions: BROADCAST_AREA_POLYGON_OPTIONS,
              }}
              onPolygonComplete={(poly) => onPolygonComplete(poly, onChange)}
            />
          );
        }}
      />
    </BroadcastAreaMap>
  );
};
