import axios from "axios";
import { getApiEndpoint } from "../utils/fallback";

export interface BaseFeedSetting {
  jurisdiction: string;
  type: "fires" | "floods" | "toban";
  source: "live" | "mock" | "custom";
}

export interface FeedSetting extends BaseFeedSetting {
  feedSettings: { [key: string]: string };
}

export interface UpdateFeedSetting extends BaseFeedSetting {
  custom?: { [key: string]: string };
}

export const getFeedSettings = (): Promise<FeedSetting[]> =>
  axios.get(`${getApiEndpoint()}/admin/settings/feeds`).then((res) => res.data);

export const updateFeedSettings = (feedSettings: UpdateFeedSetting[]) =>
  axios.patch(`${getApiEndpoint()}/admin/settings/feeds`, {
    feedSettings,
  });
