import { Container } from "../../components/container";
import { Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getWatchZoneHeatMap } from "../../api/analytics";
import { LoadingView } from "../../components/loading-view";
import { WatchZoneHeatMap } from "./components/watch-zone-heat-map";
import { HeatPalette } from "./components/heat-palette";
import { css } from "@emotion/css";

export const Analytics = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["analytics", "watchZoneHeatMap"],
    queryFn: () => getWatchZoneHeatMap(),
  });

  return (
    <Container>
      <Stack paddingY={4} spacing={3}>
        <Typography variant="h5">Watch Zone Heat Map</Typography>
        {isLoading && <LoadingView height="100%" />}
        {data && (
          <div className={css({ display: "flex" })}>
            <WatchZoneHeatMap geojson={data.geojson} />
            <HeatPalette palette={data.palette} total={data.count} />
          </div>
        )}
      </Stack>
    </Container>
  );
};
