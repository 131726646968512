import { mustEnv } from "./env";
import { memoize } from "lodash";

const apiEndpoint = mustEnv("REACT_APP_API_ENDPOINT");
const apiFallbackEndpoint = mustEnv("REACT_APP_API_FALLBACK_ENDPOINT");
const loginRedirect = mustEnv("REACT_APP_COGNITO_REDIRECT_SIGN_IN_URI");
const loginRedirectFallback = mustEnv(
  "REACT_APP_COGNITO_REDIRECT_SIGN_IN_FALLBACK_URI",
);

export const getApiEndpoint = memoize(() => {
  if (
    window.location.hostname.replace(/^admin/, "") ===
    new URL(apiEndpoint).hostname.replace(/^api/, "")
  ) {
    return apiEndpoint;
  }

  return apiFallbackEndpoint;
});

export function getLoginRedirectUri() {
  if (window.location.hostname === new URL(loginRedirect).hostname) {
    return loginRedirect;
  }
  return loginRedirectFallback;
}
