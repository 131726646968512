import { Box } from "@mui/material";
import { ReactNode } from "react";
import { Container } from "../container";

export const NAV_CONTAINER_HEIGHT = 48;

export const NavContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box borderBottom={1} borderColor="divider" height={NAV_CONTAINER_HEIGHT}>
      <Container
        sx={{
          display: "flex",
          height: "100%",
          alignItems: "center",
        }}
      >
        {children}
      </Container>
    </Box>
  );
};
