import { FormProvider, useForm } from "react-hook-form";
import { Container } from "../../components/container";
import { NavBack } from "../../components/nav";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import { Typography, Stack, TextField, Grid, Alert } from "@mui/material";
import { z } from "zod";
import { validateField } from "../../utils/form";
import { ButtonContainer } from "../create-broadcast/components/shared";
import { inviteUser } from "../../api/users";
import { SuccessWidget } from "../../components/success-widget";
import { LoadingButton } from "@mui/lab";

const inviteUserFormSchema = z.object({
  email: z
    .string()
    .min(1, "Email is required")
    .email("Must be a valid email address"),
});

type InviteUserFormSchema = z.infer<typeof inviteUserFormSchema>;

export const InviteUser = () => {
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState("");
  const formMethods = useForm<InviteUserFormSchema>({
    mode: "all",
    resolver: zodResolver(inviteUserFormSchema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formMethods;

  const onSubmit = useCallback(async (formValues: InviteUserFormSchema) => {
    const res = await inviteUser(formValues.email);
    if (res.status === 200) {
      setCompleted(true);
    } else if (res.status === 400) {
      setError(res.data.message);
    } else {
      setError("Could not invite user");
    }
  }, []);

  if (completed) {
    return (
      <SuccessWidget
        text="User has been sent an invitation email"
        returnTo="/users"
      />
    );
  }

  return (
    <>
      <NavBack />

      <Container>
        <Stack paddingTop={3}>
          <Typography variant="h5">Invite user</Typography>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                  <Stack spacing={3} paddingY={3}>
                    <TextField
                      {...register("email")}
                      label="Email"
                      {...validateField(errors, "email")}
                    />
                    {error && <Alert severity="error">{error}</Alert>}
                    <ButtonContainer>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        Send invitation
                      </LoadingButton>
                    </ButtonContainer>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Stack>
      </Container>
    </>
  );
};
