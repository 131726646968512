import { Container } from "../../components/container";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { UsersList } from "./components/users-list";

export const Users = () => {
  return (
    <Container>
      <Stack paddingY={4} spacing={3}>
        <Box>
          <Button
            variant="contained"
            startIcon={<Add />}
            component={Link}
            to="invite"
          >
            Invite user
          </Button>
        </Box>
        <Typography variant="h5">Users</Typography>
        <UsersList />
      </Stack>
    </Container>
  );
};
