import { createContext, useContext } from "react";

type SnackbarContextType = {
  showSnackbar: (message: string) => void;
};

export const SnackbarContext = createContext<SnackbarContextType>({
  showSnackbar: () => "",
});

export const useSnackbar = () => {
  const ctx = useContext(SnackbarContext);

  if (!ctx) {
    throw new Error("Must be called within <SnackbarProvider />");
  }

  return ctx;
};
