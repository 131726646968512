import { useCallback, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { Container } from "../../components/container";
import { NavBack } from "../../components/nav";
import { Coordinates, createBroadcast } from "../../api/broadcast";
import {
  createBroadcastFormSchema,
  CreateBroadcastFormSchema,
  CreateBroadcastStep,
} from "./types";
import { CreateBroadcastFields } from "./components/create-broadcast-fields";
import { ConfirmBroadcastDetails } from "./components/confirm-broadcast-details";
import { CreateBroadcastAreaMap } from "./components/create-broadcast-area-map";
import { useBroadcastTemplates } from "./components/shared";
import { SuccessWidget } from "../../components/success-widget";

export const CreateBroadcast = () => {
  const [step, setStep] = useState(CreateBroadcastStep.CREATE);
  const [searchParams] = useSearchParams();
  const initialTemplateId = searchParams.get("initialTemplateId");
  const { templates, isLoading } = useBroadcastTemplates();

  const formMethods = useForm<CreateBroadcastFormSchema>({
    mode: "all",
    resolver: zodResolver(createBroadcastFormSchema),
    defaultValues: {
      type: "DEVICES_IN_AREAS",
      selectMode: "list",
      broadcastTemplateIds: initialTemplateId ? [initialTemplateId] : [],
      drawnPolygon: [],
    },
  });
  const { control, register, handleSubmit } = formMethods;

  const createBroadcasts = useCreateBroadcasts({
    onSuccess: () => setStep(CreateBroadcastStep.COMPLETE),
  });

  const onSubmit = useCallback(
    (formValues: CreateBroadcastFormSchema) => {
      if (step === CreateBroadcastStep.CREATE) {
        setStep(CreateBroadcastStep.CONFIRM);
      } else if (step === CreateBroadcastStep.CONFIRM) {
        createBroadcasts.create(formValues);
      }
    },
    [step, createBroadcasts]
  );

  if (step === CreateBroadcastStep.COMPLETE) {
    return (
      <SuccessWidget
        text="Broadcast notification queued for sending."
        returnTo="/broadcasts"
      />
    );
  }

  const previousStep = mapStepToPreviousStep[step];

  return (
    <>
      <NavBack
        onClick={previousStep ? () => setStep(previousStep) : undefined}
      />

      <Container>
        <Stack paddingTop={3}>
          <Typography variant="h5">Create broadcast</Typography>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                  {step === CreateBroadcastStep.CREATE && (
                    <CreateBroadcastFields
                      isLoading={isLoading}
                      register={register}
                      templates={templates}
                    />
                  )}

                  {step === CreateBroadcastStep.CONFIRM && (
                    <ConfirmBroadcastDetails
                      control={control}
                      templates={templates}
                      isLoading={createBroadcasts.isLoading}
                      isError={createBroadcasts.isError}
                    />
                  )}
                </Grid>

                <Grid item xs={12} md={7}>
                  <CreateBroadcastAreaMap
                    control={control}
                    templates={templates}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Stack>
      </Container>
    </>
  );
};

type UseCreateBroadcastsProps = {
  onSuccess: () => void;
};

const useCreateBroadcasts = ({ onSuccess }: UseCreateBroadcastsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const { mutateAsync } = useMutation(
    (variables: Parameters<typeof createBroadcast>) =>
      createBroadcast(...variables)
  );

  const create = useCallback(
    async ({
      type,
      selectMode,
      broadcastTemplateIds,
      drawnPolygon,
      title,
      message,
      moreInformationUrl,
    }: CreateBroadcastFormSchema) => {
      try {
        setIsError(false);
        setIsLoading(true);

        if (type === "ALL_DEVICES") {
          await mutateAsync([
            "ALL_DEVICES",
            "Broadcast to all devices",
            title,
            message,
            undefined,
            undefined,
            moreInformationUrl,
          ]);
        } else {
          if (selectMode === "list") {
            await mutateAsync([
              "DEVICES_IN_TEMPLATE_AREAS",
              "Broadcast to template area(s)",
              title,
              message,
              undefined,
              broadcastTemplateIds,
              moreInformationUrl,
            ]);
          } else {
            await mutateAsync([
              "DEVICES_IN_AREA",
              "Broadcast to area",
              title,
              message,
              drawnPolygon as Coordinates,
              undefined,
              moreInformationUrl,
            ]);
          }
        }

        onSuccess();
      } catch {
        setIsLoading(false);
        setIsError(true);
      }
    },
    [onSuccess, mutateAsync]
  );

  return { create, isLoading, isError };
};

const mapStepToPreviousStep = {
  [CreateBroadcastStep.CREATE]: undefined,
  [CreateBroadcastStep.CONFIRM]: CreateBroadcastStep.CREATE,
  [CreateBroadcastStep.COMPLETE]: CreateBroadcastStep.CONFIRM,
} as const;
