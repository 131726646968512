import { ReactNode } from "react";
import { useAuth } from "./context";

export const RequiresAuth = ({ children }: { children: ReactNode }) => {
  const { isLoggedIn, redirectToSignIn } = useAuth();

  if (!isLoggedIn) {
    redirectToSignIn();
    return null;
  }

  return <>{children}</>;
};
