import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMemo } from "react";
import { IngestLogMessageLevel, Log } from "../../../api/data-logs";
import { LoadingView } from "../../../components/loading-view";
import { LogLevelLabel } from "../../../components/log-level-label";
import { formatDate } from "../../../utils/formatters";

export enum LogLevelFilter {
  ALL = "ALL",
  INFO = "INFO",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

type DataLogMessagesTableProps = {
  isLoading: boolean;
  data?: Log;
  filter: LogLevelFilter;
};

export const DataLogMessagesTable = ({
  isLoading,
  data,
  filter,
}: DataLogMessagesTableProps) => {
  const filteredMessages = useMemo(() => {
    const messages = data?.messages || [];

    if (filter === LogLevelFilter.ALL) {
      return messages;
    }

    return messages.filter(
      (message) => message.level === filterToLogLevel[filter]
    );
  }, [data, filter]);

  if (isLoading) {
    return <LoadingView paddingTop={8} />;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#FAFAFA" }}>
            <TableCell>Log level</TableCell>
            <TableCell>Message</TableCell>
            <TableCell align="right">Date (GMT+11)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredMessages.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {row.level && (
                  <LogLevelLabel fontWeight="bold" level={row.level} />
                )}
              </TableCell>
              <TableCell>{row.message}</TableCell>
              <TableCell align="right">{formatDate(row.createdAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const filterToLogLevel = {
  [LogLevelFilter.INFO]: IngestLogMessageLevel.INFO,
  [LogLevelFilter.WARNING]: IngestLogMessageLevel.WARNING,
  [LogLevelFilter.ERROR]: IngestLogMessageLevel.ERROR,
} as const;
