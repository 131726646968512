import { Map, MapProps } from "../map";
import { HEADER_HEIGHT } from "../header";
import { NAV_CONTAINER_HEIGHT } from "../nav";

export const BroadcastAreaMap = (props: Omit<MapProps, "height">) => {
  return (
    <Map
      {...props}
      height={`calc(100vh - ${HEADER_HEIGHT}px - ${NAV_CONTAINER_HEIGHT}px)`}
    />
  );
};
