import { Container } from "../container";
import { Button, Stack, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { Link } from "react-router-dom";

type Props = {
  text: string;
  returnTo: string;
};

export const SuccessWidget = ({ text, returnTo }: Props) => {
  return (
    <Container maxWidth="xs">
      <Stack spacing={2} paddingTop={8} alignItems="center">
        <CheckCircle color="success" sx={{ fontSize: 92 }} />
        <Stack spacing={8}>
          <Typography align="center" variant="h6">
            {text}
          </Typography>
          <Button variant="contained" component={Link} to={returnTo}>
            Return to dashboard
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};
