import {
  Alert,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingView } from "../../../components/loading-view";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { validateField } from "../../../utils/form";
import { BroadcastTemplate } from "../../../api/broadcast";
import { useCallback } from "react";
import { CreateBroadcastFormSchema } from "../types";

type Props = {
  isLoading: boolean;
  templates?: BroadcastTemplate[];
};

export const NotificationAreaFields = ({ isLoading, templates }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateBroadcastFormSchema>();

  const watchSelectMode = useWatch({
    control,
    name: "selectMode",
  });

  const renderSelectedNotificationAreas = useCallback(
    (selectedIds: unknown) => (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {(selectedIds as string[]).map((id) => (
          <Chip key={id} label={templates?.find((t) => t.id === id)?.name} />
        ))}
      </Box>
    ),
    [templates]
  );

  return (
    <>
      <Alert severity="info">
        Draw a notification area manually by using the map and controls on the
        right or select from the existing ones and review on the map.
      </Alert>
      <Stack
        spacing={1}
        border={1}
        borderColor="grey.300"
        borderRadius={1}
        padding={3}
        bgcolor="grey.100"
      >
        {isLoading ? (
          <LoadingView paddingY={2} />
        ) : (
          <>
            <Controller
              control={control}
              name="selectMode"
              render={({ field }) => (
                <FormControl>
                  <FormLabel id="select-notification-area-radio-buttons-group">
                    Select Notification Area
                  </FormLabel>
                  <RadioGroup
                    {...field}
                    aria-labelledby="select-notification-area-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="manual"
                      control={<Radio />}
                      label="Draw notification area manually"
                    />
                    {errors.drawnPolygon && (
                      <FormHelperText error>
                        {errors.drawnPolygon.message}
                      </FormHelperText>
                    )}
                    <FormControlLabel
                      value="list"
                      control={<Radio />}
                      label="Select notification area from list"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />

            {watchSelectMode === "list" && (
              <>
                {templates?.length ? (
                  <Controller
                    control={control}
                    name="broadcastTemplateIds"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        {...validateField(errors, "broadcastTemplateIds")}
                        label="Notification areas"
                        select
                        SelectProps={{
                          ...field,
                          multiple: true,
                          renderValue: renderSelectedNotificationAreas,
                          sx: { backgroundColor: "white" },
                        }}
                      >
                        {templates.map((template) => (
                          <MenuItem key={template.id} value={template.id}>
                            <Checkbox
                              checked={field.value.indexOf(template.id) > -1}
                            />
                            <ListItemText primary={template.name} />
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                ) : (
                  <Alert severity="error">
                    There are no notification areas to select. Please draw a
                    notification area manually.
                  </Alert>
                )}
              </>
            )}
          </>
        )}
      </Stack>
    </>
  );
};
