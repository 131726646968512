import { Alert, Divider, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormContext } from "react-hook-form";
import xss from "xss";
import { BroadcastTemplate } from "../../../api/broadcast";
import { LabelAndValue } from "../../../components/label-and-value";
import { CreateBroadcastControl, CreateBroadcastFormSchema } from "../types";
import { ButtonContainer, useSelectedBroadcastTemplates } from "./shared";

type ConfirmBroadcastDetailsProps = {
  control: CreateBroadcastControl;
  templates?: BroadcastTemplate[];
  isError?: boolean;
  isLoading?: boolean;
};

export const ConfirmBroadcastDetails = ({
  control,
  templates,
  isError,
  isLoading,
}: ConfirmBroadcastDetailsProps) => {
  const selectedBroadcastTemplates = useSelectedBroadcastTemplates({
    control,
    templates,
  });

  const { getValues } = useFormContext<CreateBroadcastFormSchema>();
  const values = getValues();

  return (
    <Stack spacing={3} paddingY={3} height="100%">
      {isError && (
        <Alert severity="error">
          Sorry something went wrong. Please try again.
        </Alert>
      )}

      <Stack spacing={3} height="100%" divider={<Divider />}>
        {values.type === "ALL_DEVICES" && (
          <Alert severity="warning">
            You are about to broadcast to ALL devices.
          </Alert>
        )}
        {values.type === "DEVICES_IN_AREAS" && values.selectMode === "list" && (
          <LabelAndValue
            label={
              selectedBroadcastTemplates.length > 1
                ? "Notification Areas"
                : "Notification Area"
            }
            value={
              <Stack spacing={1}>
                {selectedBroadcastTemplates.map((template) => (
                  <Typography color="inherit" key={template.id}>
                    {template.name}
                  </Typography>
                ))}
              </Stack>
            }
          />
        )}
        <LabelAndValue label="Title" value={xss(values.title)} />
        <LabelAndValue label="Message" value={xss(values.message)} />
        <LabelAndValue
          label="URL for more information"
          value={values.moreInformationUrl || <i>Not entered</i>}
        />
      </Stack>

      <ButtonContainer>
        <LoadingButton type="submit" variant="contained" loading={isLoading}>
          Send
        </LoadingButton>
      </ButtonContainer>
    </Stack>
  );
};
