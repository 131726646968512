import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  FeedSetting,
  UpdateFeedSetting,
  getFeedSettings,
  updateFeedSettings,
} from "../../../api/feedSettings";
import { LoadingView } from "../../../components/loading-view";
import { capitalize, isEqual } from "lodash";
import { ButtonContainer } from "../../create-broadcast/components/shared";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

const FEED_SOURCES = {
  live: "Live",
  mock: "S3 Mock",
  custom: "Custom",
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const FeedSettingsTable = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["feed-settings"],
    queryFn: getFeedSettings,
  });
  const [feedSettings, setFeedSettings] = useState<
    Array<FeedSetting & UpdateFeedSetting>
  >([]);
  const [customModalIndex, setCustomModalIndex] = useState<number | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (data) {
      setFeedSettings(data);
    }
  }, [data]);

  const save = useCallback(async () => {
    setIsSubmitting(true);
    await updateFeedSettings(feedSettings);
    await refetch();
    setIsSubmitting(false);
  }, [feedSettings, refetch]);

  if (isLoading) {
    return <LoadingView paddingTop={8} />;
  }

  const disableInteraction = `${process.env.REACT_APP_STAGE}`.includes("prod");

  const setFeedSettingsWithIndex = (
    idx: number,
    updatedFeedSetting: FeedSetting & UpdateFeedSetting,
  ) => {
    setFeedSettings([
      ...feedSettings.slice(0, idx),
      updatedFeedSetting,
      ...feedSettings.slice(idx + 1),
    ]);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#FAFAFA" }}>
              <TableCell>Jurisdiction</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Feed</TableCell>
              <TableCell>Rules</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedSettings?.map((feedSetting, idx) => (
              <TableRow key={`${feedSetting.jurisdiction}${feedSetting.type}`}>
                <TableCell>{feedSetting.jurisdiction.toUpperCase()}</TableCell>
                <TableCell>{capitalize(feedSetting.type)}</TableCell>
                <TableCell>
                  <Select
                    value={feedSetting.source}
                    onSelect={(v) => console.log(v)}
                    onChange={(e) => {
                      const newSource = e.target.value as
                        | "live"
                        | "mock"
                        | "custom";
                      if (newSource !== "custom") {
                        setFeedSettingsWithIndex(idx, {
                          ...feedSetting,
                          source: newSource,
                        });
                      }
                    }}
                    size="small"
                    disabled={disableInteraction}
                  >
                    {Object.entries(FEED_SOURCES).map(([feedSource, label]) => (
                      <MenuItem
                        key={feedSource}
                        value={feedSource}
                        onClick={() => {
                          if (feedSource === "custom") {
                            setCustomModalIndex(idx);
                          }
                        }}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    component={Link}
                    to={`/settings/rules/${feedSetting.jurisdiction}`}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonContainer>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          onClick={save}
          disabled={disableInteraction}
        >
          Save
        </LoadingButton>
      </ButtonContainer>
      <Modal
        open={customModalIndex !== null}
        onClose={() => setCustomModalIndex(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {customModalIndex !== null && (
            <CustomFeedSettingsForm
              feedSettings={feedSettings[customModalIndex].feedSettings}
              onSubmit={(custom) => {
                if (
                  !isEqual(custom, feedSettings[customModalIndex].feedSettings)
                ) {
                  setFeedSettingsWithIndex(customModalIndex, {
                    ...feedSettings[customModalIndex],
                    source: "custom",
                    custom,
                  });
                }
                setCustomModalIndex(null);
              }}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

function CustomFeedSettingsForm({
  feedSettings,
  onSubmit,
}: {
  feedSettings: { [key: string]: string };
  onSubmit: (values: { [key: string]: string }) => void;
}) {
  const { register, handleSubmit } = useForm({ defaultValues: feedSettings });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h4>Custom Feed Settings</h4>
      {Object.keys(feedSettings).map((key) => (
        <TextField
          key={key}
          fullWidth
          label={key}
          variant="standard"
          margin="normal"
          {...register(key)}
        />
      ))}
      <br />
      <br />
      <Button type="submit" variant="contained">
        Set
      </Button>
    </form>
  );
}
