import { Typography, TypographyProps } from "@mui/material";
import { IngestLogMessageLevel } from "../../api/data-logs";

type LogLevelLabelProps = Omit<TypographyProps, "color"> & {
  level: IngestLogMessageLevel;
};

export const LogLevelLabel = ({ level, ...props }: LogLevelLabelProps) => (
  <Typography {...props} color={mapLevelToColor[level]}>
    {mapLevelToLabel[level]}
  </Typography>
);

const mapLevelToLabel = {
  [IngestLogMessageLevel.INFO]: "Information",
  [IngestLogMessageLevel.WARNING]: "Warning",
  [IngestLogMessageLevel.ERROR]: "Error",
} as const;

const mapLevelToColor = {
  [IngestLogMessageLevel.INFO]: "#67CE84",
  [IngestLogMessageLevel.WARNING]: "#FF7800",
  [IngestLogMessageLevel.ERROR]: "#D6001C",
} as const;
