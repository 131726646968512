import { InfoOutlined } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import { differenceInMinutes } from "date-fns";

type NotificationCountTooltipProps = {
  createdAt: Date;
};

export const NotificationCountTooltip = ({
  createdAt,
}: NotificationCountTooltipProps) => {
  if (Math.abs(differenceInMinutes(createdAt, new Date())) > 15) {
    return null;
  }

  return (
    <Tooltip
      arrow
      title={
        <Typography variant="body2">
          The notification value can take several minutes to finalise after a
          broadcast is sent.
        </Typography>
      }
    >
      <InfoOutlined sx={{ fontSize: 20 }} color="info" />
    </Tooltip>
  );
};
