import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import {
  Controller,
  useFormContext,
  UseFormRegister,
  useWatch,
} from "react-hook-form";
import { BroadcastTemplate } from "../../../api/broadcast";
import { validateField } from "../../../utils/form";
import { ButtonContainer } from "./shared";
import { NotificationAreaFields } from "./notification-area-fields";
import {
  CreateBroadcastFormSchema,
  MAX_BROADCAST_MESSAGE_LENGTH,
  MAX_BROADCAST_TITLE_LENGTH,
} from "../types";

type CreateBroadcastFieldsProps = {
  isLoading: boolean;
  register: UseFormRegister<CreateBroadcastFormSchema>;
  templates?: BroadcastTemplate[];
};

export const CreateBroadcastFields = ({
  isLoading,
  register,
  templates,
}: CreateBroadcastFieldsProps) => {
  const {
    control,
    resetField,
    formState: { errors },
  } = useFormContext<CreateBroadcastFormSchema>();

  const watchType = useWatch({
    control,
    name: "type",
  });

  const watchSelectMode = useWatch({
    control,
    name: "selectMode",
  });

  const clearDrawnPolygon = useCallback(
    () => resetField("drawnPolygon"),
    [resetField]
  );

  useEffect(() => {
    if (watchType === "ALL_DEVICES" || watchSelectMode === "list") {
      clearDrawnPolygon();
    }
  }, [clearDrawnPolygon, watchSelectMode, watchType]);

  const watchTitle = useWatch({
    control,
    name: "title",
  });

  const watchMessage = useWatch({
    control,
    name: "message",
  });

  const watchDrawnPolygon = useWatch({ control, name: "drawnPolygon" });

  return (
    <Stack spacing={3} paddingY={3} height="100%">
      <Controller
        control={control}
        name="type"
        render={({ field }) => (
          <FormControl>
            <FormLabel id="broadcast-type-radio-buttons-group">
              Broadcast type
            </FormLabel>
            <RadioGroup
              {...field}
              aria-labelledby="broadcast-type-radio-buttons-group"
            >
              <FormControlLabel
                value="DEVICES_IN_AREAS"
                control={<Radio />}
                label="Devices in area(s)"
              />
              {errors.drawnPolygon && (
                <FormHelperText error>
                  {errors.drawnPolygon.message}
                </FormHelperText>
              )}
              <FormControlLabel
                value="ALL_DEVICES"
                control={<Radio />}
                label="All devices"
              />
            </RadioGroup>
          </FormControl>
        )}
      />

      {watchType === "DEVICES_IN_AREAS" && (
        <NotificationAreaFields isLoading={isLoading} templates={templates} />
      )}

      <TextField
        {...register("title")}
        label="Title"
        helperText={`${
          (watchTitle ?? "").length
        }/${MAX_BROADCAST_TITLE_LENGTH}`}
        {...validateField(errors, "title")}
      />

      <TextField
        {...register("message")}
        label="Message"
        multiline
        rows={5}
        helperText={`${
          (watchMessage ?? "").length
        }/${MAX_BROADCAST_MESSAGE_LENGTH}`}
        {...validateField(errors, "message")}
      />

      <TextField
        {...register("moreInformationUrl")}
        label="URL for more information"
        {...validateField(errors, "moreInformationUrl")}
      />

      <ButtonContainer>
        <Stack direction="row" gap={1}>
          {watchSelectMode === "manual" && (
            <Button
              variant="outlined"
              disabled={!watchDrawnPolygon.length}
              onClick={clearDrawnPolygon}
            >
              Clear area
            </Button>
          )}
          <Button type="submit" variant="contained">
            Next
          </Button>
        </Stack>
      </ButtonContainer>
    </Stack>
  );
};
