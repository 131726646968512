import { Stack, Typography } from "@mui/material";
import { Container } from "../../components/container";
import { DataLogTable } from "./components/data-log-table";

export const DataLogs = () => {
  return (
    <Container>
      <Stack paddingY={3} spacing={3}>
        <Typography variant="h5">Data Logs</Typography>
        <DataLogTable />
      </Stack>
    </Container>
  );
};
