import { Container } from "../../components/container";
import { Stack, Typography } from "@mui/material";
import { FeedSettingsTable } from "./components/feed-settings-table";

export const Settings = () => {
  return (
    <Container>
      <Stack paddingY={4} spacing={3}>
        <Typography variant="h5">Settings</Typography>
        <FeedSettingsTable />
      </Stack>
    </Container>
  );
};
