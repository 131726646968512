import { Button } from "@mui/material";
import copy from "clipboard-copy";
import { useEffect, useState } from "react";

type CodeCopyButtonProps = {
  code: string;
};

export const CopyCodeButton = ({ code }: CodeCopyButtonProps) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
    return undefined;
  }, [copied]);

  return (
    <Button
      variant="contained"
      aria-label="Copy the code"
      onClick={async () => {
        await copy(code);
        setCopied(true);
      }}
    >
      {copied ? "Copied" : "Copy"}
    </Button>
  );
};
