import { createContext, useContext } from "react";

type AuthContextType = {
  isLoggedIn: boolean;
  redirectToSignIn: () => void;
  signInWithAuthCode: (code: string) => void;
  signOut: () => void;
};

export const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  redirectToSignIn: () => null,
  signInWithAuthCode: () => null,
  signOut: () => null,
});

export const useAuth = () => {
  const ctx = useContext(AuthContext);

  if (!ctx) {
    throw new Error("useAuth must be called within <AuthProvider />");
  }

  return ctx;
};
