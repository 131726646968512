import {
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getDataLogs } from "../../api/data-logs";
import { Container } from "../../components/container";
import { NavBack } from "../../components/nav";
import {
  DataLogMessagesTable,
  LogLevelFilter,
} from "./components/data-log-messages-table";
import { SourceDetailDialog } from "./components/source-detail-dialog";

export const DataLogMessages = () => {
  const [filter, setFilter] = useState(LogLevelFilter.ALL);
  const [selectedSourceKey, setSelectedSourceKey] = useState<string>();
  const { isLoading, data } = useDataLogDetails();

  const sources = data?.sources ?? {};
  const dataSources = Object.keys(sources);

  return (
    <>
      <NavBack />

      <Container>
        <Stack paddingY={2} spacing={3}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12} md={10}>
              <Stack spacing={3}>
                <Typography variant="h5">{data?.name}</Typography>

                {!!dataSources.length && (
                  <Stack spacing={1}>
                    <Typography variant="subtitle1">Sources</Typography>
                    <Stack
                      spacing={1}
                      bgcolor="#FAFAFA"
                      alignSelf="flex-start"
                      paddingX={2}
                      paddingY={1}
                      borderRadius={3}
                    >
                      {dataSources.map((source) => (
                        <Link
                          key={sources[source]}
                          onClick={() => setSelectedSourceKey(sources[source])}
                          sx={{ cursor: "pointer" }}
                        >
                          {source}
                        </Link>
                      ))}
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel id="log-level-select-label">Log level</InputLabel>
                <Select
                  labelId="log-level-select-label"
                  value={filter}
                  label="Log level"
                  onChange={(e) => setFilter(e.target.value as LogLevelFilter)}
                >
                  <MenuItem value={LogLevelFilter.ALL}>
                    {logLevelFilterToLabel[LogLevelFilter.ALL]}
                  </MenuItem>
                  <MenuItem value={LogLevelFilter.INFO}>
                    {logLevelFilterToLabel[LogLevelFilter.INFO]}
                  </MenuItem>
                  <MenuItem value={LogLevelFilter.WARNING}>
                    {logLevelFilterToLabel[LogLevelFilter.WARNING]}
                  </MenuItem>
                  <MenuItem value={LogLevelFilter.ERROR}>
                    {logLevelFilterToLabel[LogLevelFilter.ERROR]}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <DataLogMessagesTable
            isLoading={isLoading}
            data={data}
            filter={filter}
          />
        </Stack>
      </Container>

      <SourceDetailDialog
        selectedSourceKey={selectedSourceKey}
        onClose={() => setSelectedSourceKey(undefined)}
      />
    </>
  );
};

const useDataLogDetails = () => {
  const { name, type } = useParams();

  const dataLogsQuery = useQuery({
    queryKey: ["dataLogs"],
    queryFn: getDataLogs,
  });

  const data = useMemo(
    () =>
      dataLogsQuery.data?.find(
        (d) =>
          d.name.toLowerCase() === name?.toLowerCase() &&
          d.type.replaceAll(" ", "-").toLowerCase() === type
      ),
    [name, dataLogsQuery.data, type]
  );

  return {
    data,
    isLoading: dataLogsQuery.isLoading,
  };
};

const logLevelFilterToLabel = {
  [LogLevelFilter.ALL]: "All",
  [LogLevelFilter.INFO]: "Information",
  [LogLevelFilter.WARNING]: "Warning",
  [LogLevelFilter.ERROR]: "Error",
} as const;
