import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { BroadcastTemplate } from "../../../api/broadcast";
import { formatDate } from "../../../utils/formatters";
import { Container } from "../../../components/container";
import {
  BroadcastAreaMap,
  BroadcastAreaPolygon,
} from "../../../components/broadcast-area-map";
import { LoadingView } from "../../../components/loading-view";
import { parsePolygon } from "../../../utils/geo";
import { useBroadcastTemplates } from "../../create-broadcast/components/shared";

export const BroadcastAreasList = () => {
  const navigate = useNavigate();
  const [selectedArea, setSelectedArea] = useState<BroadcastTemplate>();
  const { templates, isLoading } = useBroadcastTemplates();

  const selectedAreaPolygon = useMemo(
    () => selectedArea && parsePolygon(selectedArea.polygon),
    [selectedArea],
  );

  return (
    <Container disableGutters>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <Stack spacing={3}>
            <Typography variant="h5">Broadcast areas</Typography>
            {isLoading ? (
              <LoadingView paddingTop={8} />
            ) : (
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#FAFAFA" }}>
                    <TableCell>Name</TableCell>
                    <TableCell>Author</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(templates || []).map((row) => (
                    <TableRow
                      key={row.id}
                      hover
                      onClick={() => setSelectedArea(row)}
                      style={{
                        backgroundColor:
                          row.id === selectedArea?.id ? "#DCDCDC" : undefined,
                      }}
                    >
                      <TableCell>
                        <Typography variant="body2" fontWeight="bold">
                          {row.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {row.createdByUserName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {formatDate(row.createdAt * 1000)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          <Button
                            onClick={() =>
                              navigate(
                                `/broadcasts/create?initialTemplateId=${row.id}`,
                              )
                            }
                          >
                            Send broadcast
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={5}>
          <Stack spacing={3}>
            <Typography variant="h5">Preview</Typography>
            {selectedAreaPolygon && (
              <BroadcastAreaMap center={selectedAreaPolygon?.center}>
                <BroadcastAreaPolygon paths={selectedAreaPolygon?.paths} />
              </BroadcastAreaMap>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};
