import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  IconButton,
  Fade,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { LoadingView } from "../../../components/loading-view";
import { getDataLogSource } from "../../../api/data-logs";
import { formatXml } from "../../../utils/formatters";
import { useMemo } from "react";
import { CopyCodeButton } from "./copy-code-button";

type SourceDialogProps = {
  selectedSourceKey?: string;
  onClose: () => void;
};

export const SourceDetailDialog = ({
  selectedSourceKey,
  onClose,
}: SourceDialogProps) => {
  const isOpen = !!selectedSourceKey;

  const dataLogsQuery = useQuery({
    queryKey: ["dataLogSource", selectedSourceKey],
    queryFn: () => getDataLogSource(selectedSourceKey as string),
    enabled: isOpen,
  });

  const source = dataLogsQuery.data?.source;

  const formattedSource = useMemo(() => {
    if (!source) {
      return undefined;
    }

    if (isJsonSource(source)) {
      return JSON.stringify(JSON.parse(source), null, 4);
    }

    return formatXml(source);
  }, [source]);

  return (
    <Dialog
      fullWidth
      closeAfterTransition
      maxWidth="lg"
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Fade}
    >
      <DialogTitle borderBottom={1} borderColor="grey.300">
        Source
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 14,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {dataLogsQuery.isLoading && <LoadingView />}
        {formattedSource && (
          <pre>
            <DialogContentText variant="body2" component="code">
              {formattedSource}
            </DialogContentText>
          </pre>
        )}
      </DialogContent>
      <DialogActions>
        {source && <CopyCodeButton code={source} />}
      </DialogActions>
    </Dialog>
  );
};
const isJsonSource = (source: string) => {
  const withoutSpace = source.trim();
  return (
    (withoutSpace.startsWith("{") && withoutSpace.endsWith("}")) ||
    (withoutSpace.startsWith("[") && withoutSpace.startsWith("]"))
  );
};
