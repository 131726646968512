import { Outlet } from "react-router-dom";
import { Header } from "../header";
import { NavTabs } from "../nav";

export const DashboardLayout = () => {
  return (
    <section>
      <Header />

      <NavTabs />

      <Outlet />
    </section>
  );
};
