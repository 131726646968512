import { Box } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { useWatch } from "react-hook-form";
import {
  BROADCAST_TEMPLATES_QUERY_KEY,
  BroadcastTemplate,
  getBroadcastTemplates,
} from "../../../api/broadcast";
import { CreateBroadcastControl } from "../types";
import { useQuery } from "@tanstack/react-query";

export const ButtonContainer = ({ children }: { children: ReactNode }) => (
  <Box display="flex" justifyContent="flex-end" flexGrow={1}>
    <Box alignSelf="flex-end">{children}</Box>
  </Box>
);

type UseSelectedBroadcastAreaProps = {
  control: CreateBroadcastControl;
  templates?: BroadcastTemplate[];
};

export const useBroadcastTemplates = () => {
  const { data, isLoading } = useQuery({
    queryKey: BROADCAST_TEMPLATES_QUERY_KEY,
    queryFn: getBroadcastTemplates,
  });

  return {
    templates: data?.items,
    isLoading,
  };
};

// TODO improvement: filter server side using broadcastRepository.findBroadcastTemplateByIds
export const useFilteredBroadcastTemplates = (templateIds?: string[]) => {
  const { templates, isLoading } = useBroadcastTemplates();

  const filteredTemplates = useMemo(() => {
    if (!templates || !templateIds?.length) {
      return undefined;
    }

    return templates.filter((template) => templateIds.includes(template.id));
  }, [templateIds, templates]);

  return {
    isLoading,
    templates: filteredTemplates,
  };
};

export const useSelectedBroadcastTemplates = ({
  control,
  templates = [],
}: UseSelectedBroadcastAreaProps) => {
  const watchBroadcastTemplateIds = useWatch({
    control,
    name: "broadcastTemplateIds",
  });

  return useMemo(
    () =>
      watchBroadcastTemplateIds.map((id) =>
        templates.find((template) => template.id === id)
      ),
    [watchBroadcastTemplateIds, templates]
  ) as BroadcastTemplate[];
};
