import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Alert, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { getBroadcast } from "../../api/broadcast";
import { NavBack } from "../../components/nav";
import { Container } from "../../components/container";
import { LabelAndValue } from "../../components/label-and-value";
import { BroadcastAreaMap } from "../../components/broadcast-area-map";
import { BroadcastAreaPolygon } from "../../components/broadcast-area-map";
import { NotificationCountTooltip } from "../../components/notification-count-tooltip";
import { LoadingView } from "../../components/loading-view";
import { useFilteredBroadcastTemplates } from "../create-broadcast/components/shared";
import { formatDate, formatNumber } from "../../utils/formatters";
import { getCenterOfPolygons, parsePolygon } from "../../utils/geo";

export const ViewBroadcast = () => {
  const { id } = useParams();

  const { data, isLoading: broadcastLoading } = useQuery({
    queryKey: ["broadcast", id],
    queryFn: () => getBroadcast(id!),
  });

  const { templates, isLoading: templatesLoading } =
    useFilteredBroadcastTemplates(data?.areaTemplateIds);

  const polygons = useMemo(() => {
    if (data?.polygon) {
      return [parsePolygon(data.polygon)];
    }

    if (templates?.length) {
      return templates.map((template) => parsePolygon(template.polygon));
    }

    return undefined;
  }, [data?.polygon, templates]);

  const polygonsCenter = useMemo(() => {
    if (!polygons) {
      return undefined;
    }

    if (polygons.length === 1) {
      return polygons[0]?.center;
    }

    return getCenterOfPolygons(polygons);
  }, [polygons]);

  const area = useMemo(
    () => (
      <>
        <Typography color="GrayText">{data?.name}</Typography>
        {templates && (
          <Box mt={1} ml={2}>
            {templates.map((template) => (
              <Typography color="GrayText">{template.name}</Typography>
            ))}
          </Box>
        )}
      </>
    ),
    [data, templates]
  );

  const isLoading = templatesLoading || broadcastLoading;

  return (
    <>
      <NavBack />
      <Container>
        {isLoading ? (
          <LoadingView height="100vh" />
        ) : (
          <>
            {data && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                  <Stack spacing={3} paddingY={3} height="100%">
                    <Alert severity="info">Broadcast history details</Alert>
                    <>
                      <Stack spacing={3} height="100%" divider={<Divider />}>
                        {data.name && (
                          <LabelAndValue
                            label="Notification Area"
                            value={area}
                          />
                        )}
                        <LabelAndValue label="Title" value={data.title} />
                        <LabelAndValue label="Message" value={data.message} />
                        <LabelAndValue
                          label="URL for more information"
                          value={data.moreInformationUrl || <i>Not entered</i>}
                        />
                        {data.deviceCount !== undefined && (
                          <LabelAndValue
                            label="Notifications"
                            value={
                              <Stack
                                component="span"
                                direction="row"
                                alignItems="center"
                                spacing={0.5}
                              >
                                <Typography variant="inherit">
                                  {formatNumber(data.deviceCount)}
                                </Typography>
                                <NotificationCountTooltip
                                  createdAt={new Date(data.createdAt * 1000)}
                                />
                              </Stack>
                            }
                          />
                        )}
                        <LabelAndValue
                          label="Sent"
                          value={formatDate(data.createdAt * 1000)}
                        />
                      </Stack>
                    </>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={7}>
                  <BroadcastAreaMap center={polygonsCenter}>
                    {polygons?.map((polygon) => (
                      <BroadcastAreaPolygon paths={polygon?.paths} />
                    ))}
                  </BroadcastAreaMap>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Container>
    </>
  );
};
