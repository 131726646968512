import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { LoadingView } from "../../../components/loading-view";
import { useQuery } from "@tanstack/react-query";
import { getUsers } from "../../../api/users";
import { UserActions } from "./user-actions";

const FRIENDLY_STATUSES: Record<string, string> = {
  CONFIRMED: "Active",
  FORCE_CHANGE_PASSWORD: "Invited",
};

export const UsersList = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
  });

  if (isLoading) {
    return <LoadingView paddingTop={8} />;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: "#FAFAFA" }}>
            <TableCell>Email</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Enabled</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((user) => (
            <TableRow key={user.id}>
              <TableCell>
                <Typography variant="body2">{user.email}</Typography>
              </TableCell>
              <TableCell>
                {FRIENDLY_STATUSES[user.status] ?? user.status}
              </TableCell>
              <TableCell>{user.enabled ? "Yes" : "No"}</TableCell>
              <TableCell>
                <UserActions
                  user={user}
                  onChange={async () => {
                    await refetch();
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
