import { centerOfMass, center, points } from "@turf/turf";
import { Feature, Polygon, Position } from "@turf/helpers";

export const parsePolygonJsonString = (polygonJsonString: string) => {
  try {
    return JSON.parse(polygonJsonString) as Feature<Polygon>;
  } catch {
    return undefined;
  }
};

const getCenterLatLngOfPolygon = (polygon: Feature<Polygon>) => {
  const [lng, lat] = centerOfMass(polygon).geometry.coordinates;

  return {
    lng,
    lat,
  };
};

const getCenterLatLngOfCoordinates = (coordinates: Position[]) => {
  const [lng, lat] = center(points(coordinates)).geometry.coordinates;

  return { lng, lat };
};

export const getCenterOfPolygons = (
  polygons: (
    | {
        center: {
          lat: number;
          lng: number;
        };
      }
    | undefined
  )[]
) => {
  if (!polygons?.length) {
    return undefined;
  }

  const filtered = polygons.filter((x) => !!x) as {
    center: {
      lat: number;
      lng: number;
    };
  }[];

  return getCenterLatLngOfCoordinates(
    filtered.map(({ center }) => [center.lng, center.lat])
  );
};

export const getPolygonCoordinates = (polygon: Feature<Polygon>) => {
  return polygon.geometry.coordinates[0];
};

const getLatLngPathsOfPolygon = (polygon: Feature<Polygon>) => {
  return getPolygonCoordinates(polygon).map(([lng, lat]) => ({ lng, lat }));
};

export const parsePolygon = (polygonJsonString: string) => {
  const polygon = parsePolygonJsonString(polygonJsonString);

  if (!polygon) {
    return undefined;
  }

  return {
    center: getCenterLatLngOfPolygon(polygon),
    paths: getLatLngPathsOfPolygon(polygon),
  };
};
