import { Outlet } from "react-router-dom";
import { Header } from "../header";

export const DefaultLayout = () => {
  return (
    <section>
      <Header />

      <Outlet />
    </section>
  );
};
