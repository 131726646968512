import axios from "axios";
import { getApiEndpoint } from "../utils/fallback";
import { FeatureCollection } from "@turf/helpers";

export type HeatPalette = {
  colour: string;
  from: number;
  to: number;
}[];
export interface WatchZoneHeatMap {
  geojson: FeatureCollection;
  palette: HeatPalette;
  count: number;
}

export const getWatchZoneHeatMap = (): Promise<WatchZoneHeatMap> =>
  axios
    .get(`${getApiEndpoint()}/admin/analytics/watchZoneHeatMap`)
    .then((res) => res.data);
