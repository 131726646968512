import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { CollectionIngestRules } from "../../../api/rules";
import { IngestRuleNested } from "./ingest-rule";
import {
  RulesContainer,
  TableCell1,
  TableCell2,
  TableCell3,
  TableCell4,
  TableCell5,
} from "./common";

export const GenericRulesTable = ({
  data,
}: {
  data: CollectionIngestRules;
}) => {
  return (
    <RulesContainer>
      <Typography variant="h2">{data.name}</Typography>
      {data.website && (
        <>
          <Typography variant="h5">Website</Typography>
          <Link to={data.website.url}>{data.website.name}</Link>
        </>
      )}
      <Typography variant="h5">Feed Data</Typography>
      <Link to={data.feedUrl}>{data.feedUrl}</Link>
      <Typography variant="h5">Transform Collection Rules</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#FAFAFA" }}>
              <TableCell1>Property</TableCell1>
              <TableCell2>Rule Type</TableCell2>
              <TableCell3>Rule Value</TableCell3>
              <TableCell4>Transformation</TableCell4>
              <TableCell5>Data Type</TableCell5>
            </TableRow>
          </TableHead>
          <TableBody>
            <IngestRuleNested data={data.collectionMap} />
          </TableBody>
        </Table>
      </TableContainer>
      <div className="page-break" />
      <Typography variant="h5">Transform Item Rules</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#FAFAFA" }}>
              <TableCell1>Property</TableCell1>
              <TableCell2>Rule Type</TableCell2>
              <TableCell3>Rule Value</TableCell3>
              <TableCell4>Transformation</TableCell4>
              <TableCell5>Data Type</TableCell5>
            </TableRow>
          </TableHead>
          <TableBody>
            <IngestRuleNested data={data.itemsMap} />
          </TableBody>
        </Table>
      </TableContainer>
      {data.areaItemsMap && (
        <>
          <div className="page-break" />
          <Typography variant="h5">Area Item Rules</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "#FAFAFA" }}>
                  <TableCell1>Property</TableCell1>
                  <TableCell2>Rule Type</TableCell2>
                  <TableCell3>Rule Value</TableCell3>
                  <TableCell4>Transformation</TableCell4>
                  <TableCell5>Data Type</TableCell5>
                </TableRow>
              </TableHead>
              <TableBody>
                <IngestRuleNested data={data.areaItemsMap} />
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </RulesContainer>
  );
};
