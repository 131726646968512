import axios from "axios";
import { getApiEndpoint } from "../utils/fallback";

export enum IngestLogMessageLevel {
  INFO = 10,
  WARNING = 20,
  ERROR = 30,
}

type LogMessage = {
  level: IngestLogMessageLevel;
  message: string;
  createdAt: number;
};

type LogType = "Fire" | "Flood" | "Fire Ban";

export type Log = {
  name: string;
  type: LogType;
  level?: IngestLogMessageLevel;
  messages: LogMessage[];
  sources?: Record<string, string>;
  createdAt: number;
};

export const getDataLogs = (): Promise<Log[]> =>
  axios.get(`${getApiEndpoint()}/admin/logs`).then((res) => res.data);

export const getDataLogSource = (key: string): Promise<{ source: string }> =>
  axios
    .post(`${getApiEndpoint()}/admin/logs/source`, { key })
    .then((res) => res.data);
