import { css } from "@emotion/css";
import { ButtonBase, Stack, Typography } from "@mui/material";
import { Container } from "../container";
import { useAuth } from "../../core/auth";
import { useQuery } from "@tanstack/react-query";
import { getUser } from "../../api/auth";

export const HEADER_HEIGHT = 94;

export const Header = () => {
  const { signOut, isLoggedIn } = useAuth();

  return (
    <header
      className={css({
        height: HEADER_HEIGHT,
        display: "flex",
        alignItems: "center",
        backgroundColor: "#2957B0",
        overflow: "hidden",
      })}
    >
      <Container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" color="white">
          ADMIN DASHBOARD
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          {isLoggedIn && <LoggedInEmail />}

          <ButtonBase sx={{ color: "white" }} onClick={signOut}>
            LOG OUT
          </ButtonBase>
        </Stack>
      </Container>
    </header>
  );
};

const LoggedInEmail = () => {
  const { data } = useQuery({
    queryKey: ["users", "me"],
    queryFn: getUser,
  });

  if (!data?.email) {
    return null;
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ display: { xs: "none", md: "flex" } }}
    >
      <Typography color="white">{data.email}</Typography>
      <Typography color="white">|</Typography>
    </Stack>
  );
};
