import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { IncidentCollectionIngestRules } from "../../../api/rules";
import { IngestRuleNested } from "./ingest-rule";
import {
  RulesContainer,
  TableCell1,
  TableCell2,
  TableCell3,
  TableCell4,
  TableCell5,
} from "./common";

export const IncidentsRulesTable = ({
  data,
}: {
  data: IncidentCollectionIngestRules;
}) => {
  return (
    <RulesContainer>
      <Typography variant="h2">{data.name}</Typography>
      {data.website && (
        <>
          <Typography variant="h5">Website</Typography>
          <Link to={data.website.url}>{data.website.name}</Link>
        </>
      )}
      <Typography variant="h5">Feed Data</Typography>
      <Link to={data.feedUrl}>{data.feedUrl}</Link>
      <Typography variant="h5">Updated</Typography>
      Indicate when this feed was last updated.
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#FAFAFA" }}>
              <TableCell1></TableCell1>
              <TableCell2>Rule Type</TableCell2>
              <TableCell3>Rule Value</TableCell3>
              <TableCell4>Transformation</TableCell4>
              <TableCell5>Data Type</TableCell5>
            </TableRow>
          </TableHead>
          <TableBody>
            <IngestRuleNested
              path="updated"
              data={data.collectionMap.updated}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <div className="page-break" />
      <Typography variant="h5">Incidents</Typography>
      Loop over the following incidents:
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#FAFAFA" }}>
              <TableCell1></TableCell1>
              <TableCell2>Rule Type</TableCell2>
              <TableCell3>Rule Value</TableCell3>
              <TableCell4>Transformation</TableCell4>
              <TableCell5>Data Type</TableCell5>
            </TableRow>
          </TableHead>
          <TableBody>
            <IngestRuleNested
              path="incidents"
              data={data.collectionMap.incidents}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h5">Identifier</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#FAFAFA" }}>
              <TableCell1></TableCell1>
              <TableCell2>Rule Type</TableCell2>
              <TableCell3>Rule Value</TableCell3>
              <TableCell4>Transformation</TableCell4>
              <TableCell5>Data Type</TableCell5>
            </TableRow>
          </TableHead>
          <TableBody>
            <IngestRuleNested
              path="identifier"
              data={data.itemsMap.identifier}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <div className="page-break" />
      <Typography variant="h4">Presentation Rules</Typography>
      <Typography variant="h5">Geospatial data</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#FAFAFA" }}>
              <TableCell1>Application UI</TableCell1>
              <TableCell2>Rule Type</TableCell2>
              <TableCell3>Rule Value</TableCell3>
              <TableCell4>Transformation</TableCell4>
              <TableCell5>Data Type</TableCell5>
            </TableRow>
          </TableHead>
          <TableBody>
            <IngestRuleNested path="pin" data={data.itemsMap.pin} />
            <IngestRuleNested path="area" data={data.itemsMap.area} />
            <IngestRuleNested path="area" data={data.areaItemsMap} />
          </TableBody>
        </Table>
      </TableContainer>
      <div className="page-break" />
      <Typography variant="h5">Details screen</Typography>
      <Typography variant="body1">
        The details screen shows comprehensive incident/warning information and
        incorporates warning level, geospatial data and a{" "}
        <strong>Dynamic Section</strong> that can adapt to content available in
        the feed.
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#FAFAFA" }}>
              <TableCell1>Application UI</TableCell1>
              <TableCell2>Rule Type</TableCell2>
              <TableCell3>Rule Value</TableCell3>
              <TableCell4>Transformation</TableCell4>
              <TableCell5>Data Type</TableCell5>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(data.itemsMap)
              .filter(
                ([key, value]) =>
                  !["identifier", "pin", "area", "alertLevel", "fire"].includes(
                    key,
                  ),
              )
              .sort(([a], [b]) => {
                if (a < b) {
                  return -1;
                }
                if (a > b) {
                  return 1;
                }
                return 0;
              })
              .map(([key, value]) => (
                <IngestRuleNested key={key} path={key} data={value} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </RulesContainer>
  );
};
