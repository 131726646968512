import { Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  label: string;
  value: ReactNode;
};

export const LabelAndValue = ({ label, value }: Props) => (
  <Stack>
    <Typography>{label}</Typography>
    <Typography component="span" color="GrayText">
      {value}
    </Typography>
  </Stack>
);
