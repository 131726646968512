import { SnackbarContext } from "./context";
import { ReactNode, useCallback, useState } from "react";
import { Alert, Snackbar } from "@mui/material";

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [message, setMessage] = useState<string>();

  const handleClose = useCallback(() => {
    setMessage(undefined);
  }, []);

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar: (message) => setMessage(message),
      }}
    >
      {children}
      <Snackbar
        open={!!message}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
