import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  TransformConfigPresentation,
  IngestRulePresentation,
  IngestRuleMap,
  isIngestRulePresentation,
} from "../../../api/rules";
import {
  TableCell1,
  TableCell2,
  TableCell3,
  TableCell4,
  TableCell5,
} from "./common";

/** Handles either a IngestRule or a nested IngestRuleMap */
export function IngestRuleNested({
  data,
  path = "",
}: {
  data?: IngestRuleMap | IngestRulePresentation;
  path?: string;
}): JSX.Element {
  if (typeof data === "undefined" || isIngestRulePresentation(data)) {
    return <IngestRule key={path} id={path} data={data} />;
  }

  return (
    <>
      {Object.entries(data)
        .sort(sortRules)
        .map(([key, value]) => {
          const newPath = path ? path.concat(`.${key}`) : key;
          return <IngestRuleNested key={newPath} path={newPath} data={value} />;
        })}
    </>
  );
}

function sortRules([a]: [string, any], [b]: [string, any]) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

export function IngestRule({
  id,
  data,
}: {
  id: string;
  data?: IngestRulePresentation;
}) {
  if (!data) {
    return (
      <TableRow>
        <TableCell1>{id}</TableCell1>
        <TableCell2>n/a</TableCell2>
        <TableCell3>n/a</TableCell3>
        <TableCell4>n/a</TableCell4>
        <TableCell5>n/a</TableCell5>
      </TableRow>
    );
  }
  if (data.type === "xpath" || data.type === "jsonpath") {
    return (
      <TableRow>
        <TableCell1>{id}</TableCell1>
        <TableCell2>{data.type}</TableCell2>
        <TableCell3>{data.path}</TableCell3>
        <TableCell4>
          {data.transforms && (
            <TransformDefinitions transforms={data.transforms} />
          )}
        </TableCell4>
        <TableCell5>{data.valueType}</TableCell5>
      </TableRow>
    );
  }

  if (data.type === "constant") {
    return (
      <TableRow>
        <TableCell1>{id}</TableCell1>
        <TableCell2>constant</TableCell2>
        <TableCell3>{data.constant}</TableCell3>
        <TableCell4>
          {data.transforms && (
            <TransformDefinitions transforms={data.transforms} />
          )}
        </TableCell4>
        <TableCell5>{data.valueType}</TableCell5>
      </TableRow>
    );
  }

  if (data.type === "join") {
    return (
      <TableRow>
        <TableCell1>{id}</TableCell1>
        <TableCell2>join</TableCell2>
        <TableCell3>{data.rules}</TableCell3>
        <TableCell4>
          {data.transforms && (
            <TransformDefinitions transforms={data.transforms} />
          )}
        </TableCell4>
        <TableCell5>{data.valueType}</TableCell5>
      </TableRow>
    );
  }

  if (data.type === "reference" || data.type === "custom") {
    return (
      <TableRow>
        <TableCell1>{id}</TableCell1>
        <TableCell2>{data.type}</TableCell2>
        <TableCell3>{data.definition}</TableCell3>
        <TableCell4>
          {data.transforms && (
            <TransformDefinitions transforms={data.transforms} />
          )}
        </TableCell4>
        <TableCell5>{data.valueType}</TableCell5>
      </TableRow>
    );
  }
}

function TransformDefinitions({
  transforms,
}: {
  transforms: TransformConfigPresentation[];
}) {
  if (transforms.length > 1) {
    return (
      <ul>
        {transforms.map((transform, index) => (
          <li key={`transform-${index + 1}`}>
            <TransformDefinition data={transform} />
          </li>
        ))}
      </ul>
    );
  }

  return <TransformDefinition data={transforms[0]} />;
}

function TransformDefinition({ data }: { data: TransformConfigPresentation }) {
  if (data.type === "mapToAlertLevel") {
    const [definition, dictStr] = data.definition.split("\n");
    const dict = JSON.parse(dictStr) as { [key: string]: string };

    return (
      <div>
        {definition}
        <br />
        <TableContainer style={{ padding: "20px" }}>
          <Table style={{ border: "1px solid #CCC" }}>
            <TableHead>
              <TableRow style={{ backgroundColor: "#FAFAFA" }}>
                <TableCell>Feed Value</TableCell>
                <TableCell>Alert Level</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(dict).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return <div>{data.definition}</div>;
}
