import { TableCell, styled } from "@mui/material";

export const TableCell1 = styled(TableCell)({ width: "10%" });
export const TableCell2 = styled(TableCell)({ width: "10%" });
export const TableCell3 = styled(TableCell)({ width: "35%" });
export const TableCell4 = styled(TableCell)({ width: "35%" });
export const TableCell5 = styled(TableCell)({ width: "10%" });

export const RulesContainer = styled("div")`
  > *,
  > .MuiTypography-root {
    display: block;
    margin-top: 24px;
  }

  .page-break {
    page-break-before: always;
  }
`;

export const RulePrintStyles = () => (
  <style>
    {`@page {
  size: auto;
  margin: 20mm;
}`}
  </style>
);
