import { Navigate, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider, CompleteLogin, RequiresAuth } from "./core/auth";
import { DashboardLayout, DefaultLayout } from "./components/layout";
import { Broadcasts } from "./pages/broadcasts";
import { BroadcastAreas } from "./pages/broadcast-areas";
import { DataLogs } from "./pages/data-logs";
import { CreateBroadcast } from "./pages/create-broadcast";
import { ViewBroadcast } from "./pages/view-broadcast";
import { DataLogMessages } from "./pages/data-log-messages";
import { Users } from "./pages/users";
import { InviteUser } from "./pages/invite-user";
import { Settings } from "./pages/settings";
import { SnackbarProvider } from "./components/snackbar";
import { Rules } from "./pages/settings/rules";
import { Analytics } from "./pages/analytics";

const queryClient = new QueryClient();

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider>
            <Routes>
              <Route path="/login" element={<CompleteLogin />} />
              <Route
                element={
                  <RequiresAuth>
                    <DashboardLayout />
                  </RequiresAuth>
                }
              >
                <Route index element={<Navigate replace to="/broadcasts" />} />
                <Route path="/broadcasts" element={<Broadcasts />} />
                <Route path="/broadcast-areas" element={<BroadcastAreas />} />
                <Route path="/logs" element={<DataLogs />} />
                <Route path="/users" element={<Users />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/settings/rules/:state" element={<Rules />} />
              </Route>

              <Route
                element={
                  <RequiresAuth>
                    <DefaultLayout />
                  </RequiresAuth>
                }
              >
                <Route
                  path="/broadcasts/create"
                  element={<CreateBroadcast />}
                />
                <Route path="/broadcasts/:id" element={<ViewBroadcast />} />
                <Route path="/logs/:name/:type" element={<DataLogMessages />} />
                <Route path="/users/invite" element={<InviteUser />} />
              </Route>
            </Routes>
          </SnackbarProvider>
        </QueryClientProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
