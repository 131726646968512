import { useMemo } from "react";
import { Tabs, Tab } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { NavContainer } from "./nav-container";

export const NavTabs = () => {
  const location = useLocation();

  const value = useMemo(
    () =>
      Object.keys(routeToLabel).findIndex((route) =>
        location.pathname.startsWith(route),
      ),
    [location],
  );

  return (
    <NavContainer>
      <Tabs value={value >= 0 ? value : 0}>
        {Object.entries(routeToLabel).map(([route, label]) => (
          <Tab
            key={route}
            component={Link}
            label={label}
            to={route}
            sx={{ textTransform: "none" }}
          />
        ))}
      </Tabs>
    </NavContainer>
  );
};

const routeToLabel = {
  "/broadcasts": "Broadcast",
  "/broadcast-areas": "Broadcast areas",
  "/logs": "Data logs",
  "/users": "Users",
  "/analytics": "Analytics",
  "/settings": "Settings",
} as const;
