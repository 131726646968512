import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getDataLogs } from "../../../api/data-logs";
import { LoadingView } from "../../../components/loading-view";
import { LogLevelLabel } from "../../../components/log-level-label";
import { formatDate } from "../../../utils/formatters";

export const DataLogTable = () => {
  const dataLogsQuery = useQuery({
    queryKey: ["dataLogs"],
    queryFn: getDataLogs,
  });

  const navigate = useNavigate();

  if (dataLogsQuery.isLoading) {
    return <LoadingView paddingTop={8} />;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#FAFAFA" }}>
            <TableCell>Jurisdiction</TableCell>
            <TableCell>Type</TableCell>
            <TableCell align="right">Created at (GMT+11)</TableCell>
            <TableCell align="right">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(dataLogsQuery.data ?? []).map((row, index) => (
            <TableRow
              key={`${row.name}-${index}`}
              hover
              sx={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `${row.name.toLowerCase()}/${row.type
                    .replaceAll(" ", "-")
                    .toLowerCase()}`
                )
              }
            >
              <TableCell component="th" scope="row">
                <Typography fontWeight="bold">{row.name}</Typography>
              </TableCell>
              <TableCell>{row.type}</TableCell>
              <TableCell align="right">{formatDate(row.createdAt)}</TableCell>
              <TableCell align="right">
                {row.level && <LogLevelLabel level={row.level} />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
