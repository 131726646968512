export const validateField = <
  Obj extends Record<string, { message?: string }>,
  Key extends keyof Obj
>(
  errors: Obj,
  inputName: Key
) => {
  if (inputName in errors) {
    return {
      helperText: errors[inputName].message,
      error: true,
    } as const;
  }
};
