import { css } from "@emotion/css";
import { HeatPalette as HeatPaletteType } from "../../../api/analytics";

const paletteItemClass = css({
  flex: 1,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  textWrap: "nowrap",
});
const paletteSwatchClass = css({
  width: 40,
  height: 40,
  margin: 15,
  borderWidth: 1,
  borderColor: "#444",
});

export function HeatPalette({
  palette,
  total,
}: {
  palette: HeatPaletteType;
  total: number;
}) {
  return (
    <div className={css({ display: "flex", flexDirection: "column" })}>
      {palette.map(({ colour, from, to }) => (
        <div className={paletteItemClass} key={colour}>
          <div
            className={`${paletteSwatchClass} ${css({
              backgroundColor: colour,
            })}`}
          />
          {from} - {to}
        </div>
      ))}
      <div className={`${paletteItemClass} ${css({ alignSelf: "center" })}`}>
        <strong>Total: {total}</strong>
      </div>
    </div>
  );
}
