import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "./context";
import { useNavigate } from "react-router-dom";
import { LoadingView } from "../../components/loading-view";

export const CompleteLogin = () => {
  const { signInWithAuthCode } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  useEffect(() => {
    (async () => {
      if (code) {
        await signInWithAuthCode(code);
        navigate("/");
      }
    })();
  }, [code, signInWithAuthCode, navigate]);

  return <LoadingView height="100vh" />;
};
