import { Container } from "../../components/container";
import { Box, Button, Stack } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { BroadcastAreasList } from "./components/broadcast-areas-list";

export const BroadcastAreas = () => {
  return (
    <Container>
      <Stack paddingY={4} spacing={3}>
        <Box>
          <Button
            variant="contained"
            startIcon={<Add />}
            component={Link}
            to="create"
            disabled
          >
            Create broadcast area
          </Button>
        </Box>
        <BroadcastAreasList />
      </Stack>
    </Container>
  );
};
