import { PolygonF, PolygonProps } from "@react-google-maps/api";

type BroadcastAreaPolygonProps = {
  paths: PolygonProps["path"];
};

export const BROADCAST_AREA_POLYGON_OPTIONS = {
  fillColor: "#D6001C",
  strokeColor: "#D6001C",
  strokeWeight: 1,
};

export const BroadcastAreaPolygon = ({ paths }: BroadcastAreaPolygonProps) => (
  <PolygonF paths={paths} options={BROADCAST_AREA_POLYGON_OPTIONS} />
);
