import axios from "axios";
import { getApiEndpoint } from "../utils/fallback";

type PagedResponse<T> = {
  items: T[];
};

export type BroadcastTemplate = {
  id: string;
  name: string;
  polygon: string;
  createdAt: number;
  uppercaseName: string;
  createdByUserId: string;
  createdByUserName?: string;
};

type Broadcast = {
  id: string;
  createdAt: number;
  title: string;
  message: string;
  moreInformationUrl?: string;
  polygon?: string;
  areaTemplateIds?: string[];
  createdByUserId: string;
  createdByUserName?: string;
  name?: string;
  deviceCount?: number;
};

type BroadcastSummary = Omit<Broadcast, "polygon">;

export type Coordinates = [number, number][];

export const BROADCAST_TEMPLATES_QUERY_KEY = ["broadcastTemplates"];

export const getBroadcastTemplates = (): Promise<{
  items: BroadcastTemplate[];
}> =>
  axios
    .get(`${getApiEndpoint()}/admin/broadcastTemplates`)
    .then((res) => res.data);

export const getBroadcasts = (): Promise<PagedResponse<BroadcastSummary>> =>
  axios.get(`${getApiEndpoint()}/admin/broadcasts`).then((res) => res.data);

export const getBroadcast = (id: string): Promise<Broadcast> =>
  axios
    .get(`${getApiEndpoint()}/admin/broadcasts/${id}`)
    .then((res) => res.data);

export const createBroadcast = (
  type: "DEVICES_IN_AREA" | "DEVICES_IN_TEMPLATE_AREAS" | "ALL_DEVICES",
  name: string,
  title: string,
  message: string,
  coordinates?: Coordinates,
  areaTemplateIds?: string[],
  moreInformationUrl?: string,
): Promise<Broadcast> =>
  axios
    .post(`${getApiEndpoint()}/admin/broadcasts`, {
      type,
      name,
      title,
      message,
      coordinates,
      areaTemplateIds,
      moreInformationUrl,
    })
    .then((res) => res.data);
