import { Button, CircularProgress } from "@mui/material";
import {
  deactivateUser,
  reactivateUser,
  resendUserInvitation,
  User,
} from "../../../api/users";
import { useCallback, useState } from "react";
import { useSnackbar } from "../../../components/snackbar";

type Props = {
  user: User;
  onChange: () => Promise<void>;
};

export const UserActions = ({ user, onChange }: Props) => {
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const deactivate = useCallback(async () => {
    setLoading(true);
    await deactivateUser(user.id);
    await onChange();
    setLoading(false);
    showSnackbar(`${user.email} has been deactivated`);
  }, [onChange, showSnackbar, user]);

  const reactivate = useCallback(async () => {
    setLoading(true);
    await reactivateUser(user.id);
    await onChange();
    setLoading(false);
    showSnackbar(`${user.email} has been reactivated`);
  }, [onChange, showSnackbar, user]);

  const resendInvitation = useCallback(async () => {
    setLoading(true);
    await resendUserInvitation(user.id);
    await onChange();
    setLoading(false);
    showSnackbar(`Invitation has been resent to ${user.email}`);
  }, [onChange, showSnackbar, user]);

  if (loading) {
    return <CircularProgress size={15} style={{ marginLeft: 24 }} />;
  }

  return (
    <>
      {user.enabled && user.status === "FORCE_CHANGE_PASSWORD" && (
        <Button onClick={resendInvitation}>Resend invitation</Button>
      )}
      <Button onClick={() => (user.enabled ? deactivate() : reactivate())}>
        {user.enabled ? "Deactivate" : "Reactivate"}
      </Button>
    </>
  );
};
