import axios from "axios";
import { getApiEndpoint } from "../utils/fallback";

export type User = {
  id: string;
  email: string;
  enabled: boolean;
  status: string;
};

export const getUsers = (): Promise<User[]> =>
  axios.get(`${getApiEndpoint()}/admin/users`).then((res) => res.data);

export const inviteUser = (email: string) =>
  axios.post(`${getApiEndpoint()}/admin/users`, { email });

export const deactivateUser = (id: string) =>
  axios.patch(`${getApiEndpoint()}/admin/users/${id}/deactivate`);

export const reactivateUser = (id: string) =>
  axios.patch(`${getApiEndpoint()}/admin/users/${id}/reactivate`);

export const resendUserInvitation = (id: string) =>
  axios.patch(`${getApiEndpoint()}/admin/users/${id}/resendInvitation`);
